import React from "react";
import Footer from "../../components/common/footer";
import Navbar from "../../components/common/navbar";
import Img5 from "../../assets/images/nieve.png";

const notFound = () => {
    return (
        <>
            <Navbar />
            <main className="py-5 text-white bg-main d-flex align-items-center">
                <img className="mb-4 img-fluid nieve" src={Img5} alt="Logo" />
                <div className="max-width">
                    <h2>
                        Sin resultados. favor escanea los codigos qr y encuentra
                        el cuento.
                    </h2>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default notFound;
