import React from "react";
import Img1 from "../../assets/images/step3/img1.png";
import Img2 from "../../assets/images/step3/img2.png";
const Total = () => {
    return (
        <>
            <div className="mt-5 max-width">
                <h2>
                    ¡FELICIDADES YA ESTÁS PARTICIPANDO POR UN VIAJE A NEW YORK!
                </h2>
            </div>
            <div className="mt-5">
                <img className="img-fluid step2-img1" src={Img2} alt="Logo" />
            </div>
        </>
    );
};

export default Total;
