import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "./pages/notFound/index";
import Home from "./pages/home/index";
import Gracias from "./pages/gracias/gracias";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/404" element={<NotFound />} />
                    <Route path="/:historyName" element={<Home />} />
                    <Route path="/gracias" element={<Gracias />} />
                    <Route path="/" element={<NotFound />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
