import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { database } from "../../firebaseConfig";
import { serverTimestamp, doc, collection, setDoc } from "firebase/firestore";
import { UseHistory } from "../../context";
import { useNavigate } from "react-router-dom";
import { validateRUT } from "validar-rut";

import $ from "jquery";
import Inputmask from "inputmask";

var Fn = {
    // Valida el rut con su cadena completa "XXXXXXXX-X"
    validaRut: function (rutCompleto) {
        rutCompleto = rutCompleto.replace("‐", "-");
        rutCompleto = rutCompleto.replace(/\./g, "");
        console.log(rutCompleto);
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
        var tmp = rutCompleto.split("-");
        var digv = tmp[1];
        var rut = tmp[0];
        if (digv == "K") digv = "k";

        return Fn.dv(rut) == digv;
    },
    dv: function (T) {
        var M = 0,
            S = 1;
        for (; T; T = Math.floor(T / 10))
            S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
        return S ? S - 1 : "k";
    },
};

const ValidationFinal = () => {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();
    const { history } = UseHistory();
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();

    const emailValidation = async (data) => {
        setLoader(true);

        const { rut } = data;
        const newDataUser = { ...data, calculatedCreatedAt: serverTimestamp() };
        /* console.log(newDataUser); */
        if (!Fn.validaRut(rut)) {
            setLoader(false);
            return setError("rut", {
                type: "valid",
                message: "Ingresa un rut valido",
            });
        }

        const usersRef = collection(database, "users");
        await setDoc(doc(usersRef), {
            ...newDataUser,
        });
        setTimeout(() => {
            setLoader(false);
            window.location = "/gracias";
            //navigate("/gracias");
            /* switch (history) {
                case 1:
                    navigate("/CUENTO-CARRUSEL/gracias");
                    break;
                case 2:
                    navigate("/CUENTO-TRONO-VIEJITO-PASCUERO/gracias");
                    break;
                case 3:
                    navigate("/CUENTO-ARBOL-Y-ANIMALES/gracias");
                    break;
                default:
                    console.log("sin historia", history);
                    navigate("404");
                    break;
            } */
        }, 1000);
    };

    useEffect(() => {
        var selector = document.getElementById("rut");
        /* Inputmask("[99.999.999]-[9|K|k]").mask(selector); */
        Inputmask("9{1,2}.9{3}.9{3}-(9|k|K)", {
            numericInput: true,
            casing: "upper",
        }).mask(selector);
    }, []);

    return (
        <>
            <form
                className="mt-5 mb-3 text-center"
                onSubmit={handleSubmit(emailValidation)}
            >
                <h2 className="text-center">
                    Completa tus datos para participar.
                </h2>
                <div className="mb-3">
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Nombre"
                        {...register("name", {
                            required: "El campo es requerido",
                        })}
                    />
                    {errors.name && (
                        <span role="alert">{errors.name.message}</span>
                    )}
                </div>
                <div className="mb-3">
                    <input
                        type="text"
                        className="form-control"
                        id="Apellido"
                        placeholder="Apellido"
                        {...register("lastName", {
                            required: "El campo es requerido",
                        })}
                    />
                    {errors.lastName && (
                        <span role="alert">{errors.lastName.message}</span>
                    )}
                </div>
                <div className="mb-3">
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        {...register("email", {
                            required: "El email es requerido",
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "ingrese un correo electrónico válido",
                            },
                        })}
                    />
                    {errors.email && (
                        <span role="alert">{errors.email.message}</span>
                    )}
                </div>
                <div className="mb-3">
                    <input
                        type="text"
                        className="form-control"
                        id="comuna"
                        placeholder="Comuna"
                        {...register("comuna", {
                            required: "El campo es requerido",
                        })}
                    />
                    {errors.lastName && (
                        <span role="alert">{errors.lastName.message}</span>
                    )}
                </div>
                <div className="mb-3">
                    <input
                        type="number"
                        className="form-control"
                        id="phone"
                        maxLength={9}
                        placeholder="Teléfono "
                        {...register("phone", {
                            required: "El campo es requerido",
                            maxLength: {
                                value: 9,
                                message:
                                    "El campo no puede superar los 9 caracteres",
                            },
                        })}
                    />
                    {errors.phone && (
                        <span role="alert">{errors.phone.message}</span>
                    )}
                </div>
                <div className="mb-3">
                    <input
                        type="text"
                        className="form-control"
                        id="rut"
                        placeholder="Rut"
                        {...register("rut", {
                            required: "El campo es requerido",
                        })}
                    />
                    {errors.rut && (
                        <span role="alert">{errors.rut.message}</span>
                    )}
                </div>
                <div>
                    {loader && (
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    )}

                    {!loader && (
                        <button type="submit" className="btn btn-dark">
                            Participar
                        </button>
                    )}
                </div>
            </form>
        </>
    );
};

export default ValidationFinal;
