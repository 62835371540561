import React, { useState } from "react";
import { UseHistory } from "../../context";

import Img1 from "../../assets/images/historyCarrusel/img1.png";
import Img2 from "../../assets/images/historyCarrusel/img2.png";
import Img3 from "../../assets/images/historyViejito/img1-2.png";
import Img4 from "../../assets/images/historyArbol/img1.png";

import Img4b from "../../assets/images/historyArbol/img1b.png";
import ImgPersonaje1 from "../../assets/images/historyArbol/img-personaje1.png";
import ImgPersonaje12 from "../../assets/images/historyArbol/img-personaje1-2.png";
import ImgPersonaje2 from "../../assets/images/historyArbol/img-personaje2.png";
import ImgPersonaje3 from "../../assets/images/historyArbol/img-personaje3.png";

const History1 = () => {
    console.log("History1");
    const { changeStep, history } = UseHistory();
    const [loader] = useState(false);
    const readHistory = () => {
        changeStep(2);
    };
    return (
        <>
            {history === 1 && (
                <>
                    <div className="max-width max-width-history">
                        <h2 className="mt-5">El carrusel de los deseos</h2>
                        <p>
                            Sector Rosario esconde un secreto. Lleno de magia y
                            diversión: Un carrusel mágico y aventurero ¡que gira
                            y gira cumpliendo tus deseos!
                            <br />
                            <br />
                            Este carrusel viene del Polo Norte y su dueño es el
                            Viejito Pascuero. Los duendes crearon esta obra de
                            arte, con amor, con oficio y mucho esmero.
                            <br />
                            <br />
                            Tú puedes ser el primero, súbete a este carrusel.
                            Pues si un deseo pides en él, lo sabrá el Viejito
                            Pascuero. ¿Estás listo?
                        </p>
                    </div>

                    {loader && (
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    )}

                    {!loader && (
                        <button
                            type="submit"
                            className="btn btn-dark"
                            onClick={readHistory}
                        >
                            Continúa para participar
                        </button>
                    )}
                    <div className="position-relative mt-5">
                        <div style={{ minHeight: "315px" }}>
                            <img
                                className="mb-0 img-fluid step2-img1 step2-carrusel-width"
                                src={Img1}
                                alt="Logo"
                            />
                        </div>
                        <img
                            className="mb-4 img-fluid history-img2"
                            src={Img2}
                            alt="Logo"
                        />
                        <img
                            className="mb-4 img-fluid history-img3"
                            src={Img2}
                            alt="Logo"
                        />
                    </div>
                </>
            )}

            {history === 2 && (
                <>
                    <div className="max-width max-width-history">
                        <h2 className="mt-5">
                            El Viejito Pascuero y sus cartas mágicas
                        </h2>
                        <p>
                            Ya faltaba poco para noche buena ¡pero al Polo Norte
                            no había llegado ni una carta! El Viejito tenía
                            mucha pena ¿Acaso toda la navidad se frena?
                            <br />
                            <br />
                            Las buscó siempre optimista sin hallar rastros de
                            ellas, ¡hasta que por fin pilló una pista! y partió
                            hacia ella, volando como centella.
                            <br />
                            <br />
                            Todas las cartas estaban reunidas ¡solo que no donde
                            siempre! Nunca estuvieron perdidas, estaban allí, en
                            frente a Zara, esperando al Viejito para ser leídas.
                            <br />
                            <br />
                            Los duendes se despertaron felices y ruidosos,
                            crearon juntos el trono más ostentoso ¡para escuchar
                            los deseos de los niños más bondadosos!
                            <br />
                            <br />Y tú, ¿ya pensaste en tu deseo? ¡Aún estás a
                            tiempo de contárselo al Viejito!
                        </p>
                    </div>
                    {loader && (
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    )}

                    {!loader && (
                        <button
                            type="submit"
                            className="my-3 btn btn-dark mb-5"
                            onClick={readHistory}
                        >
                            Continúa para participar
                        </button>
                    )}
                    <div className="position-relative mt-5">
                        <div
                            style={{
                                width: "90%",
                                textAlign: "center",
                                margin: "0 auto",
                            }}
                        >
                            <img
                                className="img-fluid step2-img1 step2-carrusel-rotate"
                                src={Img3}
                                style={{ marginBottom: "-2vh" }}
                                alt="Logo"
                            />
                            <img
                                className="mb-4 img-fluid history-img2"
                                src={Img2}
                                alt="Logo"
                            />
                            <img
                                className="mb-4 img-fluid history-img3"
                                src={Img2}
                                alt="Logo"
                            />
                        </div>
                    </div>
                </>
            )}

            {history === 3 && (
                <>
                    <div className="max-width max-width-history">
                        <h2 className="mt-5">¿Cómo armamos el arbolito?</h2>
                        <p>
                            En un mágico bosque se celebra una nueva navidad
                            están todos los animalitos incluidos. Y con mucha
                            emoción reunidos, ¡todos listos para esta hermosa
                            festividad!
                            <br />
                            <br />
                            El trabajo es decorar el árbol más bello del lugar.
                            Huemules, conejos, zorros y chinchillas Trabajan
                            juntos sin parar.
                            <br />
                            <br />
                            Unos ponen lindos adornos, otros ponen lucecitas en
                            el contorno, y todos descansan tomando turnos,
                            ¡esperando el momento de poner la estrella!
                            <br />
                            <br />
                            No importa cómo sea tu arbolito cómo es su forma, su
                            color o su tipo. Si es muy grande o muy pequeñito.
                            Lo importante es que todos juntos lo armemos en
                            familia, poco a poquito.
                        </p>
                    </div>
                    {loader && (
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    )}

                    {!loader && (
                        <button
                            type="submit"
                            className="my-3 btn btn-dark"
                            onClick={readHistory}
                        >
                            Continúa para participar
                        </button>
                    )}
                    <div>
                        <div
                            style={{
                                textAlign: "center",
                                margin: "0 auto",
                            }}
                            className="position-relative"
                        >
                            <img
                                className="img-fluid step2-img1"
                                src={Img4}
                                alt="Logo"
                            />
                            <img
                                className=" img-fluid step2-personaje1"
                                src={ImgPersonaje1}
                                alt="Logo"
                            />
                            <img
                                className=" img-fluid step2-personaje1-2"
                                src={ImgPersonaje12}
                                alt="Logo"
                            />
                            <img
                                className=" img-fluid step2-personaje2"
                                src={ImgPersonaje2}
                                alt="Logo"
                            />
                            <img
                                className=" img-fluid step2-personaje3"
                                src={ImgPersonaje3}
                                alt="Logo"
                            />
                            <img
                                className=" history3-img2"
                                src={Img2}
                                alt="Logo"
                            />
                            <img
                                className=" history3-img3"
                                src={Img2}
                                alt="Logo"
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default History1;
