import React from "react";
import Logo from "../../../assets/images/Logo_PAK.png";
const index = () => {
    return (
        <nav className="py-3 bg-white nav-parque">
            <a
                href="https://www.parquearauco.cl/"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img className="img-fluid" src={Logo} alt="Logo" />
            </a>
        </nav>
    );
};

export default index;
