import React from "react";
import Img2 from "../../assets/images/step3/img2.png";
import Img5 from "../../assets/images/nieve.png";
import Footer from "../../components/common/footer";
import Navbar from "../../components/common/navbar";
const gracias = () => {
    return (
        <>
            <Navbar />
            <main className="py-5 pb-0 overflow-hidden text-white bg-main">
                <img className="mb-4 img-fluid nieve" src={Img5} alt="Logo" />
                <div className="mt-5 max-width">
                    <h2>
                        ¡FELICIDADES YA ESTÁS PARTICIPANDO POR UN VIAJE A NEW
                        YORK!
                    </h2>
                </div>
                <div className="mt-5">
                    <img
                        className=" img-fluid step2-img1"
                        src={Img2}
                        alt="Logo"
                    />
                </div>
            </main>
            <Footer />
        </>
    );
};

export default gracias;
