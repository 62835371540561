import React, { useEffect } from "react";
import { UseHistory } from "../../context";
import { useParams, useNavigate } from "react-router-dom";

import Footer from "../../components/common/footer";
import Navbar from "../../components/common/navbar";

import History1 from "../../components/histories/History1";
import ReadTotal from "../../components/read/total";
/* import ValidationEmail from "../../components/forms/ValidationEmail"; */
import ReadCount from "../../components/read/index";

import Img1 from "../../assets/images/intro/img1.png";
import Img2 from "../../assets/images/intro/img2.png";
import Img3 from "../../assets/images/intro/img3.png";
import Img4 from "../../assets/images/intro/img4.png";
import Img5 from "../../assets/images/nieve.png";
import Img6 from "../../assets/images/logos-pak-latamNew.png";
import Img7 from "../../assets/images/bg-desktop.png";
import Img8 from "../../assets/images/nieve-desktop.png";
import Img9 from "../../assets/images/bg-desktop2.png";

const Home = () => {
    const { step, history, changeStep, changeHistory } = UseHistory();
    const { historyName } = useParams();
    const navigate = useNavigate();

    const validation = (historyName) => {
        if (historyName === "CUENTO-CARRUSEL") {
            changeHistory(1);
            return true;
        }

        if (historyName === "CUENTO-TRONO-VIEJITO-PASCUERO") {
            changeHistory(2);
            return true;
        }

        if (historyName === "CUENTO-ARBOL-Y-ANIMALES") {
            changeHistory(3);
            return true;
        }

        return false;
    };

    const goToHomePage = () => navigate("/404");

    useEffect(() => {
        if (historyName) {
            if (!validation(historyName)) {
                goToHomePage();
            }
        }
    }, [historyName]);

    return (
        <>
            <Navbar />
            <main
                className="py-5 pb-0 overflow-hidden text-white bg-main d-none d-sm-block"
                id="mainDesktop"
            >
                <img
                    className="mb-4 img-fluid nieve-desktop"
                    src={Img8}
                    alt="Logo"
                />
                <div className="container position-relative h-100">
                    <div className="row align-items-center h-100">
                        <div className="col-md-6">
                            <div className="position-relative">
                                <img
                                    className="mb-4 img-fluid"
                                    src={Img7}
                                    alt="Logo"
                                />
                            </div>
                        </div>
                        <div className="col-md-6 text-start mb-5">
                            <h3 className="mb-5">
                                ¡Disfruta toda la magia de nuestros cuentos solo
                                desde tu celular!
                            </h3>
                            <button
                                type="button"
                                className="btn btn-dark me-3 mb-2"
                                onClick={() =>
                                    (window.location =
                                        "https://www.parquearauco.cl/")
                                }
                            >
                                Volver al Inicio{" "}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path
                                        d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                                        fill="rgba(255,255,255,1)"
                                    />
                                </svg>
                            </button>
                            <button
                                type="button"
                                className="btn btn-dark mb-2 bt-2"
                                onClick={() =>
                                    (window.location =
                                        "https://www.parquearauco.cl/encuentra-todo-para-esta-navidad")
                                }
                            >
                                Ver Más{" "}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path
                                        d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                                        fill="rgba(255,255,255,1)"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </main>
            <main className="py-5 pb-0 overflow-hidden text-white bg-main d-block d-sm-none">
                <img className="mb-4 img-fluid nieve" src={Img5} alt="Logo" />
                {step === 0 && (
                    <div className="mt-5 text-center" id="step1">
                        <img
                            className="mb-4 img-fluid intro-img1"
                            src={Img1}
                            alt="Logo"
                        />
                        <div className="max-width">
                            <h2>
                                Entérate cómo nuestros personajes crearon la
                                Navidad en Parque Arauco
                            </h2>
                            <img className=" img-fluid" src={Img6} alt="Logo" />
                            <p className="mt-1">
                                Te llevan a New York, en E.E.U.U ¡Lee el cuento
                                y participa!
                            </p>
                            <button
                                type="button"
                                className="btn btn-dark"
                                onClick={() => changeStep(1)}
                            >
                                Lee el cuento
                            </button>
                        </div>
                        <div className="position-relative">
                            <img
                                className="mb-4 img-fluid intro-img2"
                                src={Img2}
                                alt="Logo"
                            />
                            <img
                                className="mb-4 img-fluid intro-img3"
                                src={Img3}
                                alt="Logo"
                            />
                            <img
                                className="mb-4 img-fluid "
                                src={Img4}
                                alt="Logo"
                            />
                        </div>
                    </div>
                )}

                {step === 1 && history && <History1 />}

                {step === 2 && <ReadCount />}

                {step === 3 && <ReadTotal />}
            </main>
            <Footer />
        </>
    );
};

export default Home;
