import React from "react";
import Logo from "../../../assets/images/Logo_PAK_white.png";
import Logo1 from "../../../assets/images/logo-1.png";
import Logo2 from "../../../assets/images/logo-2.png";
import Logo3 from "../../../assets/images/logo-3.png";
import Logo4 from "../../../assets/images/logo-4.png";
const index = () => {
    return (
        <footer>
            <div className="py-3">
                <a
                    href="https://www.parquearauco.cl/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        className="mb-4 img-fluid logo-footer"
                        src={Logo}
                        alt="Logo"
                    />
                </a>
                <ul className="rrss">
                    <li>
                        <a
                            href="https://www.facebook.com/parauco"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img className="img-fluid" src={Logo1} alt="Logo" />
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://twitter.com/parquearauco"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img className="img-fluid" src={Logo2} alt="Logo" />
                        </a>
                    </li>
                    <li>
                        <a
                            href="http://"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img className="img-fluid" src={Logo3} alt="Logo" />
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.youtube.com/channel/UC9ZtYWgO0_Fz7Fm7A3loI8A"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img className="img-fluid" src={Logo4} alt="Logo" />
                        </a>
                    </li>
                </ul>
            </div>
            <p className="py-2 leyend">2022 © Todos los derechos reservados.</p>
        </footer>
    );
};

export default index;
