import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyATuLy0vqrdZTnu5uoa1rPZC6XmKTuhmE8",
    authDomain: "pak-e441c.firebaseapp.com",
    projectId: "pak-e441c",
    storageBucket: "pak-e441c.appspot.com",
    messagingSenderId: "649814731857",
    appId: "1:649814731857:web:f09cb63d70542d34c41c70",
    measurementId: "G-TR4Z534D1J",
};

export const app = initializeApp(firebaseConfig);
export const database = getFirestore(app);
