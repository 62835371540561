import { createContext, useContext, useState } from "react";
/*
step 1 validation

step 2

step 3 

step 4 mensaje de ya leiste el cuento

*/
const HistoryContext = createContext({
    history: 0,
    step: 0,
    count: 0,
    changeStep: () => {},
    changeHistory: () => {},
    changeCount: () => {},
    userData: {},
});

const UseHistory = () => {
    return useContext(HistoryContext);
};

const HistoryContextProvider = ({ children }) => {
    const [history, setHistory] = useState(0);
    const [step, setStep] = useState(0);
    const [count, setCount] = useState(0);
    const [userData, setUserData] = useState({});

    const changeStep = (index) => setStep(index);

    const changeCount = (count) => setCount(count);

    const changeHistory = (index) => setHistory(index);

    const changeUser = (user) => setUserData({ ...user });

    const context = {
        history: history,
        step: step,
        count: count,
        userData: userData,
        countHistory: step,
        changeStep: changeStep,
        changeCount: changeCount,
        changeHistory: changeHistory,
        changeUser: changeUser,
    };

    return (
        <HistoryContext.Provider value={context}>
            {children}
        </HistoryContext.Provider>
    );
};

export { UseHistory, HistoryContextProvider };
