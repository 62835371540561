import React from "react";
import { UseHistory } from "../../context";
import Img1 from "../../assets/images/step3/img1.png";
import Img2 from "../../assets/images/img2.png";
import ValidationFinal from "../../components/forms/ValidationFinal";
const index = () => {
    console.log("final");
    return (
        <>
            <div className="mt-5 max-width">
                <div
                    style={{
                        width: "60%",
                        textAlign: "center",
                        margin: "0 auto",
                    }}
                >
                    <img
                        className="img-fluid step2-img1"
                        src={Img2}
                        alt="Logo"
                    />
                </div>
                <ValidationFinal />
            </div>
            <div className="position-relative">
                <img className="img-fluid step2-img1" src={Img1} alt="Logo" />
            </div>
        </>
    );
};

export default index;
